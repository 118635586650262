<template>
  <page-layout>
    <div slot="headerContent">
      <h1 class="title">文章发布</h1>
    </div>
    <a-row :gutter="19">
      <a-col :xl="19" :lg="24" :md="24" :sm="24" :xs="24">
        <a-card>
          <a-input
            placeholder="文章标题最大255个字节"
            v-model="releaseFormItem.article.name"/>
          <TextRich v-model="releaseFormItem.article.content"
                    style="border: 1px solid #d9d9d9;border-radius: 5px;margin-top: 20px;min-height: 912px"></TextRich>
        </a-card>
      </a-col>
      <a-col :xl="5" :lg="24" :md="24" :sm="24" :xs="24">
        <a-card>
          <div slot="title">
            <a-icon type="file-done"/>
            发布
          </div>
          <a-row>
            <a-col span="24">
              <a-icon type="check-square"/>
              <span style="margin-left: 10px;margin-right: 10px;">状态</span>
              <a-select
                style="width: 165px"
                placeholder="请选择状态"
                v-model="releaseFormItem.article.status">
                <a-select-option
                  v-for="item in ReleaseStatusType"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
          <a-row style="padding-bottom: 10px;margin-top: 10px;">
            <a-col span="24">
              <a-icon type="up-square"/>
              <span style="margin-left: 10px;margin-right: 10px;">置顶这篇文章</span>
              <a-checkbox @change="onChange" :checked="releaseFormItem.article.ifTop"></a-checkbox>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="24">
              简述：
              <a-textarea
                placeholder="请输入简述"
                v-model="releaseFormItem.article.sketch"
                :rows="4"/>
            </a-col>
            <a-col span="24">
              <single-simple-upload v-model="releaseFormItem.mainPhotoList" class="m-t-12" @change="photoChange"
                                    style="margin-top: 18px"></single-simple-upload>
            </a-col>
          </a-row>
          <div style="margin-top: 20px;float: right">
            <a-button @click="saveDraft" :loading="saveLoading">保存草稿</a-button>
            <a-button type="primary" @click="releaseSubmit" style="margin-left: 8px"
                      :loading="commitLoading">
              发布
            </a-button>
          </div>
        </a-card>
        <a-card style="margin-top: 15px">
          <div slot="title">
            <a-icon type="align-left"/>
            分类
          </div>
          <div class="tree-panel">
            <z-tree ref="treeRef"
                    :load-url="treeLoadUrl"
                    :other-params="treeParams"
                    :auto-load="false"
                    :show-edit="false"
                    @checkClick="checkClick"
                    type="checkbox">
            </z-tree>
          </div>
        </a-card>
        <a-card style="margin-top: 15px">
          <div slot="title">
            <a-icon type="tags"/>
            标签
          </div>
          <a-row>
            <a-col span="24">
              <template v-for="(tag) in tags">
                <a-tooltip v-if="tag.length > 20" :key="tag.id" :title="tag.name">
                  <a-tag :key="tag.id" closable :afterClose="() => handleClose(tag)">
                    {{`${tag.name.slice(0, 20)}...`}}
                  </a-tag>
                </a-tooltip>
                <a-tag :key="tag.id" closable :afterClose="() => handleClose(tag)">
                  {{tag.name}}
                </a-tag>
              </template>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="20">
              <a-select
                @change="handleChange"
                style="width: 95%"
                mode="multiple"
                placeholder="请选择标签"
                v-decorator="['checkUserIds', {rules: [{ required: true, message: '请选择标签'}]} ]">
                <a-select-option
                  v-for="item in tagList"
                  :key="item.key"
                  :value="item.value"
                  :label="item.value">
                  {{item.value}}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col span="4">
              <a-button @click="jumpAdd">新增</a-button>
            </a-col>
          </a-row>
          <a-modal
            title="博客标签添加"
            v-model="visible"
            okText="添加"
            cancelText="取消"
            width="660px"
            @ok="handleOk">
            <Article-Tag-form ref="tagRef"
                              @Success="handleAddSuccess"
                              @Error="handleAddError">
            </Article-Tag-form>
          </a-modal>
        </a-card>
      </a-col>
    </a-row>
  </page-layout>
</template>
<script>
  import ArticleTagForm from '../tag/ArticleTagForm'
  import { ReleaseStatusType, ReleaseStatusTypeAdd } from '../../../api/vo/lab/selectDataVO'
  import PageLayout from '@/components/page/PageLayout'
  import TextRich from '../../../components/TextRich/TextRich'
  import ZTree from '../../../components/ztree'
  import releaseArticleVO from '../../../api/vo/blog/releaseArticleVO'
  import SERVICE_URLS from '../../../api/service.url'
  import SingleSimpleUpload from '../../../components/LocalUpload/SingleSimpleUpload'

  export default {
    name: 'Release',
    data() {
      return {
        tagList: [],
        visible: false,
        treeLoadUrl: SERVICE_URLS.article.type.tree,
        releaseFormItem: releaseArticleVO(),
        saveLoading: false,
        commitLoading: false,
        ReleaseStatusType: ReleaseStatusTypeAdd(),
        instanceId: null,
        tempTagIds: [],
        tempType: [],
        tempTypeIds: [],
        ids: [],
        tags: [],
        id: '',
        mainPhotoList: [],
        treeParams: {
          blogId: ''
        }
      }
    },
    components: { TextRich, PageLayout, ZTree, ArticleTagForm, SingleSimpleUpload },
    mounted() {
      this.treeParams.blogId = this.$router.currentRoute.query['id']
      this.$nextTick(() => {
        this.$refs.treeRef.loadTree()
      })
      this.getTagList()
      this.releaseFormItem.article.user.id = this.currentUser.id
      if (this.$router.currentRoute.query['type'] === 'add') {
        this.ReleaseStatusType = ReleaseStatusTypeAdd()
        this.instanceId = undefined
      } else if (this.$router.currentRoute.query['type'] === 'edit') {
        this.instanceId = this.$router.currentRoute.query['id']
        this.releaseFormItem.article.user.id = this.currentUser.id
        this.ReleaseStatusType = ReleaseStatusType()
        if (this.instanceId) {
          this.loadData(this.instanceId)
        }
      }
    },
    computed: {
      currentUser() {
        return this.$store.getters.currentUser
      }
    },
    methods: {
      handleClose(removedTag) {
        this.tempTagIds = []
        const tags = this.tags.filter(tag => tag !== removedTag)
        this.tags = tags
        for (let item of tags) {
          this.tempTagIds.push(item.id)
        }
      },
      onChange(e) {
        this.releaseFormItem.article.ifTop = e.target.checked
      },
      getTagList() {
        this.$http(this, {
          url: SERVICE_URLS.article.tag.select,
          noTips: 'true',
          success: (data) => {
            this.tagList = data.body
          }
        })
      },
      jumpAdd() {
        this.visible = true
      },
      handleOk() {
        this.$refs['tagRef'].addTagSubmit()
        this.getTagList()

      },
      handleAddSuccess() {
        this.visible = false
      },
      handleAddError() {
        this.visible = false
      },
      handleChange(value, option) {
        this.tempTagIds = []
        this.tags = []
        for (let item of option) {
          this.tempTagIds.push(item.key)
        }
        // this.tags.push(value)
      },
      checkClick(treeNodes) {
        this.tempTypeIds = []
        for (let item of treeNodes) {
          this.tempTypeIds.push(item.id)
        }
      },
      releaseSubmit() {
        this.commitLoading = true
        this.releaseFormItem.article.status = 'release'
        this.setRelease()
        this.$http(this, {
          url: SERVICE_URLS.article.release.release,
          data: this.releaseFormItem,
          noTips: 'true',
          success: (data) => {
            this.$message.success('发布成功！')
            this.commitLoading = false
            this.$router.push('/blog/list/list')
          },
          error: (data) => {
            this.commitLoading = false
          }
        })
      },
      photoChange(body) {
        this.$nextTick(() => {
          this.releaseFormItem.article.mainPhoto = body.bucketUrl + body.fileUrl
        })
      },
      saveDraft() {
        /*  this.releaseFormItem = releaseArticleVO()*/
        this.saveLoading = true
        this.releaseFormItem.article.status = 'draft'
        this.setRelease()
        this.$http(this, {
          url: SERVICE_URLS.article.release.release,
          data: this.releaseFormItem,
          noTips: 'true',
          success: (data) => {
            this.$message.success('保存草稿成功！')
            this.saveLoading = false
          },
          error: (data) => {
            this.saveLoading = false
          }
        })
      },
      setRelease() {
        this.releaseFormItem.article.id = this.id
        this.releaseFormItem.tagIds = this.tempTagIds
        this.releaseFormItem.typeIds = this.tempTypeIds
      },
      loadData(id) {
        this.$http(this, {
          url: SERVICE_URLS.article.list.view,
          params: {
            id: id
          },
          noTips: true,
          success: (data) => {
            this.releaseFormItem.article = data.body.article
            this.id = data.body.article.id
            this.mainPhotoList = data.body.article.mainPhoto
            for (let item of data.body.blogTypes) {
              this.tempTypeIds.push(item.id)
            }
            for (let item of data.body.blogTags) {
              this.tempTagIds.push(item.id)
              // this.tags.push(item.name)
            }
            this.tempType = data.body.blogTypes
            this.tags = data.body.blogTags
            // console.log(data.body.blogTags, 456987)
            this.mainPhotoList = []
            if (data.body.article.mainPhoto) {
              this.mainPhotoList.push({
                fileUrl: data.body.article.mainPhoto,
                w: 300,
                h: 300
              })
            }
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>