const articleTagVO = function () {
  return {
    id: '',
    name: '',
    status: true,
    sorter: 0,
    sumArticle: 0,
    creater: '',
    dateCreated: '',
    lastUpdated: ''
  }
}
export default articleTagVO
