export const selectDataVO = function () {
  return {
    id: '',
    no: '',
    name: '',
    nick: '',
    fullName: '',
    hasChildren: false,
    parentId: null,
    parentIds: '',
    sorter: 0,
    creater: '',
    dateCreated: '',
    lastUpdated: ''
  }
}
//状态下拉框
export const StatusType = function () {
  return [
    { label: '正常', value: '正常' },
    { label: '禁用', value: '禁用' }
  ]
}
//发布编辑状态下拉框
export const ReleaseStatusType = function () {
  return [
    { label: '草稿', value: 'draft' },
    { label: '待审核', value: 'review' },
    { label: '已发布', value: 'release' },
    { label: '审核不通过', value: 'notApproved' }
  ]
}
//发布添加状态下拉框
export const ReleaseStatusTypeAdd = function () {
  return [
    { label: '草稿', value: 'draft' },
    { label: '待审核', value: 'review' }
  ]
}
// 实验项目类型下拉选择框
export const ExperimentType = function () {
  return [
    { label: '演示实验', value: '演示实验' },
    { label: '分组实验', value: '分组实验' }
  ]
}
// 实验项目科目下拉选择框
export const SubjectType = function () {
  return [
    { label: '化学', value: '化学' },
    { label: '物理', value: '物理' },
    { label: '生物', value: '生物' }
  ]
}
//申请主页的表格表头
export const Columns = function () {
  return [
    {
      title: '编号',
      dataIndex: 'no',
      align: 'left',
      width: 150
    },
    {
      title: '状态',
      scopedSlots: { customRender: 'steps' },
      width: 120
    },
    {
      title: '教学主题',
      dataIndex: 'name',
      align: 'left',
      width: 200
    },
    {
      title: '教学形式',
      dataIndex: 'type',
      align: 'left',
      width: 100
    },
    {
      title: '教学科目',
      dataIndex: 'subject',
      align: 'left',
      width: 100
    },
    {
      title: '单位机构',
      dataIndex: 'organization.name',
      align: 'left',
      width: 260
    },
    {
      title: '部门处室',
      dataIndex: 'department.name',
      align: 'left',
      width: 150
    },
    {
      align: 'center',
      title: '备注说明',
      dataIndex: 'memo',
      width: 150
    },
    {
      title: '操作',
      align: 'center',
      width: 150,
      dataIndex: 'operation',
      scopedSlots: { customRender: 'operation' }
    }
  ]
}


