<template>
  <a-spin :spinning="spinShow">
    <a-form layout="horizontal" :form="form">
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="标签名称">
            <a-input
              v-decorator="['name']"
              placeholder="输入标签名称"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="排序">
            <a-input-number
              style="width: 100%"
              :step="0.1"
              v-decorator="['sorter', {rules:[
          {type:'number',required:true,message:'排序号必须为数字'}
          ],trigger:'change',initialValue:0}]"
              placeholder="输入排序号"></a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="状态">
            <a-select
              @change="handleChange"
              placeholder="请选择状态"
              v-decorator="[ 'status',{rules: [{ required: true, message: '请选择状态'}]}]">
              <a-select-option
                v-for="item in statusType"
                :key="item.value"
                :value="item.value"
                :label="item.label">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <!-- <a-form-item v-if="type === 'edit'">
         <a-affix :offsetBottom="10">
           <a-button type="primary" style="float: right"
                     :loading="ifSub"
                     @click="submitEdit">提交修改
           </a-button>
         </a-affix>
       </a-form-item>-->
    </a-form>
  </a-spin>
</template>

<script>

  import SERVICE_URLS from '../../../api/service.url'
  import articleTagVO from '../../../api/vo/blog/articleTagVO'
  import { StatusType } from '../../../api/vo/lab/selectDataVO'

  export default {
    name: 'ArticleTagForm',
    props: {
      type: {
        type: String,
        default: 'edit'
      }
    },
    data() {
      return {
        options: [],
        formItem: articleTagVO(),
        statusType: StatusType(),
        spinShow: false,
        form: this.$form.createForm(this),
        ifSub: false,
        status: false,
        instanceId: undefined
      }
    },
    methods: {
      handleChange(value) {
        if (value === '正常') {
          this.status = true
        } else {
          this.status = false
        }
      },
      /*
       * 通过ID获取数据
       */
      loadData(id) {
        this.$http(this, {
          url: SERVICE_URLS.article.tag.view,
          params: {
            id: id
          },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.instanceId = id
            this.$nextTick(() => {
              this.setFields(data.body)
            })
          }
        })
      },
      setFields(values) {
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          name: this.formItem.name,
          status: this.formItem.status === true ? '正常' : '禁用',
          sorter: this.formItem.sorter
        })
      },
      setVOFields(values) {
        this.formItem.name = values.name
        this.formItem.status = this.status
        this.formItem.sorter = values.sorter
      },
      /**
       * 加载下拉框数据，添加时使用
       * @param id 默认选中值
       */
      /* addTagSubmit () {
         this.form.validateFields(
           (err, values) => {
             if (!err) {
               this.setVOFields(values)
               this.$http(this, {
                 url: SERVICE_URLS.article.tag.create,
                 data: this.formItem,
                 success: (data) => {
                   //添加成功，清空表单数据，并触发回调
                   this.$emit('addSuccess', data.body)
                   //清空表单数据
                   this.formItem = articleTagVO()
                   this.form.resetFields()
                 },
                 error: () => {
                   this.$emit('addError')
                 }
               })
             }
           }
         )
       },*/
      handleTagDel(id) {
        this.$http(this, {
          url: SERVICE_URLS.article.tag.delete,
          params: {
            id: id
          },
          success: () => {
            this.$emit('Success')
          }
        })
      },
      addTagSubmit() {
        if (this.instanceId) {
          this.form.validateFields((err, values) => {
            if (!err) {
              if (!err) {
                this.setVOFields(values)
                this.$http(this, {
                  url: SERVICE_URLS.article.tag.update,
                  data: this.formItem,
                  params: {
                    id: this.instanceId
                  },
                  success: (data) => {
                    this.setFields(data.body)
                    this.$emit('Success', data.body)
                  }
                })
              } else {
              }
            }
          })
        } else {
          this.form.validateFields(
            (err, values) => {
              if (!err) {
                this.setVOFields(values)
                this.$http(this, {
                  url: SERVICE_URLS.article.tag.create,
                  data: this.formItem,
                  success: (data) => {
                    //添加成功，清空表单数据，并触发回调
                    this.$emit('Success', data.body)
                    //清空表单数据
                    this.formItem = articleTagVO()
                    this.form.resetFields()
                  },
                  error: () => {
                    this.$emit('Error')
                  }
                })
              }
            }
          )
        }
      }

    }
  }
</script>